import React from 'react'
import {
  OurPeopleModalProps,
  OurPeopleModal,
} from '../components/OurPeopleModal'

import samImage from '../../assets/images/jpg/headshots/Sam.jpg'
import garryImage from '../../assets/images/jpg/headshots/GarryG.png'
import danImage from '../../assets/images/jpg/headshots/DanD.jpg'
import steveImage from '../../assets/images/jpg/headshots/SteveE.jpg'
import bryanImage from '../../assets/images/jpg/headshots/BryanH.jpg'
import robertImage from '../../assets/images/jpg/headshots/RobertM.jpg'

import sladeImage from '../../assets/images/jpg/headshots/SladeJ.jpg'
import nathanImage from '../../assets/images/jpg/headshots/NathanE.png'
import quentinImage from '../../assets/images/jpg/headshots/QuentinA.jpg'
import lanceImage from '../../assets/images/jpg/headshots/LanceL.jpg'
import tomImage from '../../assets/images/jpg/headshots/TomK.jpg'
import songImage from '../../assets/images/jpg/headshots/SongD.png'
import zackImage from '../../assets/images/jpg/headshots/ZackE.jpg'
import jonImage from '../../assets/images/jpg/headshots/JonH.jpg'
import AlexImage from '../../assets/images/jpg/headshots/AlexH.jpg'
import JasonImage from '../../assets/images/jpg/headshots/JasonO.jpg'
import HadenImage from '../../assets/images/jpg/headshots/HadenH.png'

/**
 * Returns the OurPeople Section for the about page
 * @returns {*} JSX.Element
 */
export function OurPeopleSection(): JSX.Element {
  const leaders: OurPeopleModalProps[] = [
    {
      imgSrc: samImage,
      name: 'Sam Roundy',
      description: `With a degree in Computer Engineering, Sam has spent his
      20-year career studying and architecting automated test frameworks.
      Having trained and mentored hundreds of test and measurement engineers,
      Sam has a passion for helping others grow. Sam prides himself in being a
      thought leader in the test and measurement community and is the most
      recognized author on TestStand forums. He helped architect and write the
      Certified TestStand Developer exam for NI. In 2015, Sam co-founded
      Testeract, with a mission to progress the automated test industry.
      He currently serves as its President. Sam is a LabVIEW Champion,
      Certified LabVIEW Architect, Certified TestStand Architect, and a
      Certified Professional Instructor.`,
      link: 'https://www.linkedin.com/in/sam-roundy-a136754/',
    },
    {
      imgSrc: garryImage,
      name: 'Garry Garrett',
      description: `Garry has 21 years of experience in test and measurement
      engineering, with a focus on RF and communication systems,
      instrumentation, and architecting hardware abstraction layers. He has
      extensive experience working in a variety of industries, including DoD,
      oil and gas, energy, mining, consumer electronics, and radio astronomy.
      In 2015, Garry co-founded Testeract. Garry graduated with bachelor's
      degrees in Math and Physics from Morehead State University in eastern
      Kentucky. He completed graduate work in engineering at the University of
      Utah. He is a Certified LabVIEW Architect and a Certified TestStand
      Architect. He has a passion for data analysis and mathematical modeling.`,
      link: 'https://www.linkedin.com/in/garry-garrett-2345397a/',
    },
    {
      imgSrc: danImage,
      name: 'Dan Davis',
      description: `Dan earned a BS in Computer Science from Brigham Young
      University. After finishing his degree, Dan started his 12-year career
      working for defense contractor, L3Harris. While at L3, Dan worked as a
      Software Engineer on many projects, but he quickly realized he loved
      working on LabVIEW and TestStand software in the Test Engineering
      organization. Dan earned his CLA (Certified LabVIEW Architect) and CTA
      (Certified TestStand Architect) while in that position. Dan later became
      the Manager of the Automation Test Engineering team leading those LabVIEW
      and TestStand developers. Dan left L3Harris and is now serving as the
      head of Integration Services for Testeract, where he leads a team of
      engineers helping our customers solve difficult problems in the Test
      Engineering field.`,
      link: 'https://www.linkedin.com/in/daniel-davis-8159a06/',
    },
    {
      imgSrc: steveImage,
      name: 'Steve Evans',
      description: `Steve has over 20 years of experience developing
      organizational talent for engineering, higher education, medical, and
      service organizations. As the head of TestPoint Academy, Steve utilizes
      his passion for change management, instructional design, mentoring, and
      people development to ensure individuals succeed in their job roles.
      During his career, he has implemented L&D strategies and initiatives that
      have reduced turnover, increased new hire time-to-proficiency, and saved
      companies millions of dollars. In 2012, Steve was selected as one of ten
      Top Young Trainers by Training Magazine. He holds an MBA from Western
      Governors University, a master's degree in Instructional Psychology and
      Technology from Brigham Young University, and a bachelor's degree in
      International Studies from Brigham Young University.`,
      link: 'https://www.linkedin.com/in/stephendevans/',
    },
    {
      imgSrc: robertImage,
      name: 'Robert Mortensen',
      description: `After starting his career at National Instruments
      supporting, teaching, and developing LabVIEW, Robert decided he liked
      using LabVIEW more than making it. So, he left NI and worked for
      integration companies Moore Good Ideas, VPI Engineering, and Endigit
      before landing at Testeract. Robert earned his undergraduate degree in
      Computer Engineering as well as an MBA from Brigham Young University.
      He hasn't given up his love for LabVIEW since he started using it nearly
      20 years ago. He is passionate about helping Testeract achieve its goal
      of revolutionizing the test industry as a technical guru and head of
      internal operations. Robert is a Certified LabVIEW Architect, Certified
      LabVIEW Embedded Systems Developer, former LabVIEW Champion, and
      three-time winner of the World's Fastest LabVIEW Programmer challenge.`,
      link: 'https://www.linkedin.com/in/robertcmortensen/',
    },
    {
      imgSrc: bryanImage,
      name: 'Bryan Heslop',
      description: `Bryan has spent his career helping companies be more
      effective with test engineering. He spent over eight years with NI
      (National Instruments) in applications engineering and sales roles in New
      Mexico and Utah. He then spent nearly six years at Endigit leading the
      sales and marketing activities and developing test software. At
      Testeract, Bryan is responsible for leading the sales and marketing
      efforts. Throughout his career Bryan has worked directly with hundreds of
      companies to improve processes and strategies for automated test. He has
      seen and is happy to share what companies can accomplish with effective
      teams and tools. Bryan earned an undergraduate degree in Computer
      Engineering and an MBA from Brigham Young University. He is a Certified
      LabVIEW Architect.`,
      link: 'https://www.linkedin.com/in/bryanheslop/',
    },
  ]
  const mentors: OurPeopleModalProps[] = [
    {
      imgSrc: sladeImage,
      name: 'Slade Jeppesen',
      description: `In 2018, Slade received his first internship at L3
      Technologies and started work in the Test and Measurement world.
      During his senior year of college, Slade joined Testeract and went on to
      earn his CLA and CTA and to experience all sorts of test needs. Slade
      graduated from Brigham Young University with a bachelor's degree in
      Computer Engineering. He thoroughly enjoys tackling the challenges and
      innovative ideas that have come with his career. In his free time, Slade
      enjoys spending time with family, camping, and being outdoors. He also
      enjoys reading about upcoming technology or playing games with friends.`,
      link: 'https://www.linkedin.com/in/slade-jeppesen/',
    },
    {
      imgSrc: nathanImage,
      name: 'Nathan Engler',
      description: `Nathan graduated from the University of Pittsburgh's
      Swanson School of Engineering in Spring of 2021. While at Pitt, he earned
      a Bachelor of Science in Mechanical Engineering and was exposed to a
      variety of topics ranging from micro/nano manufacturing to finite element
      analysis. Nathan's favorite project centered on the development of a
      baseball that helps Little Leaguers build fundamental fielding skills.
      He worked as a Manufacturing Engineer for a year before joining Testeract
      in July of 2022.`,
      link: 'https://www.linkedin.com/in/nathan-engler/',
    },
    {
      imgSrc: quentinImage,
      name: 'Quentin Alldredge',
      description: `Quentin, also known as “Q”, graduated from Utah State
      University with a B.S. and M.S. in Mechanical Engineering. After
      graduation, Q worked first at Alliance Tech Systems (ATK) and later for
      the US Air Force. In 2016, Q became a CLA, and his team was certified as
      a LabVIEW Center of Excellence. In 2018, Q was accepted as a LabVIEW
      Champion and at NIWeek 2019 he received the Community Contribution Award
      for his creation of the QControl Toolkit. In August 2021, Q joined
      Testeract, as their Chief LabVIEW Architect. Q also serves on the board of
      GCentral and is involved in many other LabVIEW Community initiatives.`,
      link: 'https://www.linkedin.com/in/quentin-q-alldredge/',
    },
    {
      imgSrc: lanceImage,
      name: 'Lance Leavitt',
      description: `Lance graduated from Brigham Young University with a
      bachelor's in Computer Science and a minor in Design. He is currently a
      Software Engineer at Testeract where he works with LabVIEW, SystemLink,
      Python, and various JavaScript frameworks. He is driven each day to help
      customers create solutions that work for their specific needs. Lance has
      a passion for continuous learning and enjoy helping others do the same.`,
      link: 'https://www.linkedin.com/in/lance-leavitt/',
    },
    {
      imgSrc: tomImage,
      name: 'Tom Kantner',
      description: `Tom graduated from UCLA in 2019 with a BS in Aerospace
      Engineering. With 4 years of professional experience, he has worked in
      the Aerospace/Defense, Process Automation, and Transportation industries.
      He has worked on several projects, including various Test Stations for
      Space System hardware, high-speed rail systems, and PLCs. His expertise
      includes GUI development, HIL test automation, FPGA design, and automated
      script development in a variety of languages and development
      environments.`,
      link: 'https://www.linkedin.com/in/thomasakantnerii/',
    },
    {
      imgSrc: jonImage,
      name: 'Jonathan Holt',
      description: `Jonathan is an experienced software engineer who is
      passionate about programming education. He has taught in many settings
      from one-on-one tutoring in college to teaching programming classes to
      high school students. Since joining Testeract he has worked on testing a
      variety of devices from adhesive to transmissions. Jonathan has a
      bachelor's degree in Computer Science from Utah Valley University.`,
      link: 'https://www.linkedin.com/in/jonathan-w-holt/',
    },
    {
      imgSrc: songImage,
      name: 'Song Du',
      description: `As an integration Team Lead at Testeract, Song is
      responsible for providing integration and consulting services to some of
      Testeract's largest customers. He brings two decades of experience across
      software development, project management, and manufacturing management.
      He's passionate about developing innovative software applications as well
      as improving organizational management processes. Song holds a Master's
      of Engineering in Electrical and Computer Engineering(ECE) from Cornell
      University and a Bachelor's in ECE from University of Utah. He also holds
      the Project Management Professional(PMP) certification.`,
      link: 'https://www.linkedin.com/in/song-du-53a3003/',
    },
    {
      imgSrc: zackImage,
      name: 'Zack Ence',
      description: `Zack Graduated from Brigham Young University in 2019 with a
      Bachelor's degree in Electrical Engineering.  After graduation, Zack
      began work for Northrop Grumman as an electrical engineering specializing
      in rocket systems.  Eventually Zack moved on to spend several years
      working with the airforce as a test engineer for fighter pilot training
      tools.  There he became very familiar with government and military test
      standards and procedures.  In 2022, Zack joined Testeract and began
      putting his skills to the test in commercial Test and Measurement
      applications.  Outside of work, Zack enjoys spending time with his wife
      and kids, playing soccer and tennis, and tinkering with electronics
      in his basement.`,
      link: 'https://www.linkedin.com/in/zackary-ence-895731150/',
    },
    {
      imgSrc: AlexImage,
      name: 'Alex Hudson',
      description: `Alex Hudson is a Computer Engineer with a passion for
      embedded systems. This shows through his personal and competitive
      projects, including his first-place finish in Utah Ignite's active
      transportation programming competition. Alex has a history of sharing his
      passion with others by speaking at schools and working with at-risk youth
      to prepare and take the AP programming test. He has a diverse skillset
      including customer communication, technical problem solving, and a
      variety of programing languages. Currently, he is the lead developer on a
      several TE Connectivity projects and manages the acquisition of hardware,
      software development, and integration.`,
      link: 'https://www.linkedin.com/in/alex-m-hudson/',
    },
    {
      imgSrc: JasonImage,
      name: 'Jason Orr',
      description: `Jason graduated from BYU with a B.S. in Mechanical
      Engineering and went to NI immediately after graduation, where he fell
      in love with TestStand, automation, and software development. In 2018,
      Jason left to work at SISU, an industrial robotics and automation company,
      where he worked for 4.5 years in software development, architecture,
      project management, and engineering management. With a deep practical
      knowledge and experience in automation systems integration, software
      development, robotics, engineering project and people management, Jason
      joined Testeract in 2022 and is excited for the opportunity to help build
      the world's best test and automation systems and engineers.`,
      link: 'https://www.linkedin.com/in/jorr3/',
    },
    {
      imgSrc: HadenImage,
      name: 'Haden Heath',
      description: `After graduating with a bachelor's degree in Mechanical
      Engineering from Brigham Young University, Haden got straight to work
      developing LabVIEW applications at Endigit. In just over 4 years there,
      he became a Certified LabVIEW Developer, organized LabVIEW user groups,
      and led many project teams. His passion for problem solving and making
      efficient systems has helped blend his technical experience with the
      business side of project management and small business growth. Now at
      Testeract, Haden has used his LabVIEW experience to create excellent test
      and measurement systems. He loves spending time outdoors and being
      physically active.`,
      link: 'https://www.linkedin.com/in/haden-heath-64485ab5/',
    },
  ]
  return (
    <div className="bg-sky-blue sm:ml-[17%] xs:h-[4800px]">
      <div className="w-1/3 pb-32 xs:pb-36 xs:ml-[40%] xs:mt-28  xs:scale-150">
        <div className="text-5xl font-semibold text-dark-blue xs:w-[350px]">
          Our People
        </div>
        <div className="text-lg xs:text-xl mt-5 xs:w-[350px]">
          Our leaders, recruiters, trainers, and mentors are experts in their
          fields. The have created the standard for test and measurement
          engineering.
        </div>
      </div>
      <div className="pl-4 xs:ml-[47.5%] xs:py-10 xs:flex xs:scale-150">
        <span className="text-dark-blue font-bold italic mb-5 xs:mb-32 xs:mt-10">
          / OUR LEADERS
        </span>
      </div>
      <div className="z-50 grid grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 sm:items-center xs:items-center sm:mt-10 xs:mt-24 xs:ml-[44%] sm:gap-x-4 xs:gap-14 gap-7 m-4 pb-16  xs:scale-150">
        {leaders.map(({ imgSrc, name, description, link }) => (
          <OurPeopleModal
            key={name}
            imgSrc={imgSrc}
            name={name}
            description={description}
            link={link}
          />
        ))}
      </div>
      <div className="pl-4 xs:ml-[47.5%] xs:mt-96 xs:flex z-10 xs:scale-150">
        <span className="text-dark-blue font-bold italic mb-5 xs:mb-40">
          / OUR MENTORS
        </span>
      </div>
      <div className="z-50 grid grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 sm:items-center xs:items-center sm:mt-16 xs:mt-80 xs:ml-[44%] sm:gap-x-4 xs:gap-14 gap-7 m-4 pb-20 xs:scale-150">
        {mentors.map(({ imgSrc, name, description, link }) => (
          <OurPeopleModal
            key={name}
            imgSrc={imgSrc}
            name={name}
            description={description}
            link={link}
          />
        ))}
      </div>
    </div>
  )
}
export default OurPeopleSection
