import React from 'react'
import ActionDescription from '../../../components/ActionDescription'
import whoWeAreImg from '../../../../assets/images/jpg/stock_photos/LandingWhoWeAre.jpg'
import Circles from '../../../components/svg/SvgPatternDecoration'

/**
 * Who We Are section of the Landing page.
 *
 * @return {*}  {JSX.Element}
 */
const WhoWeAre = (): JSX.Element => (
  <div className="relative h-[250px] sm:h-[300px] xs:h-[350px] xs:mb-[400px] mb-20 mt-10 w-full min-w-[250px] flex flex-row">
    <div className="w-[25%]">
      <Circles
        svgprops={{ stroke: '#D9D8D6', strokeWidth: 2 }}
        className="absolute -left-[50px] -top-[200px] opacity-25 z-5"
      />
    </div>
    <div className="flex absolute -top-[175px] left-[175px] xs:flex-col xs:-top-[140px] xs:left-[30px] sm:left-[10px] z-0 min-w-full sm:scale-110 xs:scale-125">
      <ActionDescription
        imageProps={{
          src: whoWeAreImg,
          altText:
            'Young woman sitting in front of a laptop surrounded by cables and testing equipment.',
          size: 'medium',
          outerCircle: { decorations: '' },
          innerCircle: false,
        }}
        slashBoxProps={{
          slashText: 'WHO WE ARE',
          slashColor: 'text-dark-gray',
          descriptionColor: 'text-black',
          description:
            'We are a talent acquisition and development company for engineers that offers comprehensive recruiting, training, mentoring, and consulting services so engineers can learn and grow without incurring missed opportunity costs, generating organizational confidence in their abilities.',
        }}
        buttonProps={{ title: 'About us' }}
        buttonLink="/about"
        className="mx-auto w-[60%]"
      />
    </div>
  </div>
)

export default WhoWeAre
