import React from 'react'
import Flag from '../../../components/Flag'

const TrainingBottom = () => (
  <div className="flex flex-col justify-center w-full pt-24">
    <div className="w-[30%] ml-[13%] text-xl font-light">
      Our standard learning path mirrors our approach to test engineering.
    </div>
    <div className="services-diagonal-light-blue w-full">
      <div className="flex flex-rows gap-8 pt-12 justify-center">
        <Flag
          title="Logistics"
          list={[
            'Development Process',
            'Test Plans',
            'Test Procedures',
            'Test Data and Reports',
            'Common Tools',
            'Root Cause',
            'Peer Reviews',
          ]}
        />
        <div className="pt-12">
          <Flag
            title="Software"
            list={[
              'Source Code Control',
              'Software Architecture',
              'Instrument Communication',
              'LabVIEW',
              'TestStand',
            ]}
          />
        </div>
        <div className="pt-24">
          <Flag
            title="Hardware"
            list={[
              'Instrumentation',
              'Buses',
              'Data Acquisition',
              'Power Supplies',
              'Oscilloscopes',
            ]}
          />
        </div>
      </div>
    </div>
  </div>
)

export default TrainingBottom
