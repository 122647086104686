import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type NavLinkProps = {
  isHeader: boolean
  navigateTo: string
  label: string
}

/**
 * Is the browser at the current link?
 *
 * @param {string} route
 * @return {*}  {boolean}
 */
function isSelected(route: string): boolean {
  const location = useLocation()
  return Boolean(location.pathname === '/' + route)
}

/**
 * Add decorations to the Link Text or not.
 *
 * @param {string} route
 * @return {*}  {string}
 */
function getSelectedHeaderLinkStyles(route: string): string {
  const base = 'absolute flex items-center -rotate-45 bottom-8 -left-5 visible'
  if (isSelected(route)) {
    return `${base} text-link-blue`
  }
  return base
}

/**
 * Display the checkmark or not.
 *
 * @param {string} route
 * @return {*}  {string}
 */
function selectCheckmark(route: string): string {
  if (isSelected(route)) {
    return 'link-checkmark visible'
  }
  return 'link-checkmark invisible'
}

/**
 * Select the text color based on whether the component is in the header or not.
 *
 * @param {boolean} isHeader
 * @return {*}  {string}
 */
function getBaseLinkColor(isHeader: boolean): string {
  return isHeader ? 'text-white' : 'text-dark-blue'
}

/**
 * An individual navigation link. Note: the `min-w-[95px]` is set so that all
 * nav links are the same width. 95px is the width of "CONTACT US", if more
 * links are added with wider text spans, this value will need to be adjusted.
 *
 * @return {*}  {JSX.Element}
 */
const NavLink = ({
  isHeader,
  navigateTo,
  label,
}: NavLinkProps): JSX.Element => (
  <div
    className={`relative ${isHeader && selectCheckmark(`${navigateTo}`)}`}
    data-testid="checkmark"
  >
    <Link
      to={`/${navigateTo}`}
      className={`${getBaseLinkColor(
        isHeader
      )} text-sm font-bold leading-4 tracking-widest min-w-[110px] whitespace-nowrap ${
        isHeader && getSelectedHeaderLinkStyles(`${navigateTo}`)
      }`}
    >
      {label}
    </Link>
  </div>
)

export default NavLink
