import React from 'react'
import { Link } from 'react-router-dom'
import ArrowButton from './ArrowButton'

export type BulletPointProps = {
  icon?: string
  iconAlt?: string
  title?: string
  description: string
  buttonText?: string
  buttonLink?: string // can be mailto:, tel:, https:
}

/**
 * Bullet point with an icon as the bullet, bold title and normal description
 *
 * @param {BulletPointProps} {
 *   icon,
 *   iconAlt,
 *   title,
 *   description,
 *   buttonText,
 *   buttonLink,
 * }
 * @return {*}  {JSX.Element}
 */
const BulletPoint = ({
  icon,
  iconAlt,
  title,
  description,
  buttonText,
  buttonLink,
}: BulletPointProps): JSX.Element => (
  <div className="flex w-1/2">
    <img src={icon} alt={iconAlt} className="pr-5" />
    <div className="">
      {title && (
        <div className="text-base text-dark-blue font-bold tracking-wider">
          {title}
        </div>
      )}
      <div className="text-lg mt-5 text-black leading-7">{description}</div>
      {buttonText && buttonLink && (
        <div className="w-fit mt-3">
          <Link
            to="#"
            onClick={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              window.location.href = buttonLink!
              e.preventDefault()
            }}
          >
            <ArrowButton title={buttonText} isBlue={true} />
          </Link>
        </div>
      )}
    </div>
  </div>
)

export default BulletPoint
