import React from 'react'

const StairPoints = (): JSX.Element => (
  <div className="absolute w-full h-full z-10 top-0 sm:ml-[10%]">
    <div className="absolute top-16 left-[44rem] text-dark-blue">
      <h1 className="font-bold text-2xl">Month 12</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Designing</li>
        <li>Leading</li>
      </ol>
    </div>
    <div className="absolute top-48 left-[37rem] text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 10</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Decision Making</li>
      </ol>
    </div>

    <div className="absolute top-[19rem] left-[30rem] text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 8</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Individual Projects</li>
      </ol>
    </div>
    <div className="absolute bottom-56 left-[23rem] text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 6</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Contributing</li>
        <li>Assignments</li>
        <li>*Proficient</li>
      </ol>
    </div>
    <div className="absolute bottom-32 left-64 text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 4</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Small Tasks</li>
        <li>Shadowing</li>
      </ol>
    </div>
    <div className="absolute bottom-5 left-36 text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 2</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Introduction</li>
        <li>Learning</li>
      </ol>
    </div>
  </div>
)

export default StairPoints
