import React from 'react'
import WhyChooseUsTable from '../../../components/WhyChooseUsTable'

import bigCheckmark from '../../../../assets/images/svg/decorations/BigCheckmark.svg'

/**
 * Why Choose Us section for Landing page
 *
 * @return {*}  {JSX.Element}
 */
const WhyChooseUs = (): JSX.Element => (
  <>
    <div className="relative min-w-[1440px] xs:h-[1400px] w-full bg-dark-blue z-10 outline outline-1 outline-dark-blue">
      <WhyChooseUsTable />
      <img
        src={bigCheckmark}
        alt="Really big checkmark"
        className="absolute -top-[525px] right-1 opacity-40 -z-10 sm:-right-10 sm:scale-125 xs:scale-150 overflow-clip"
      />
    </div>
  </>
)

export default WhyChooseUs
