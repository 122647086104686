import React from 'react'
import SlashTextBox from './SlashTextBox'
import CostTable from './CostTable'
import ArrowButton from './ArrowButton'
import { Link } from 'react-router-dom'

/**
 *
 * Why Choose Us Section of Landing Page
 *
 * @return {*}  {JSX.Element}
 */
const WhyChooseUsTable = (): JSX.Element => (
  <div className="grid place-items-center z-20 sm:scale-110 xs:scale-125 xs:mt-20">
    <div className="w-full grid grid-cols-2 xs:grid-cols-1 gap-y-5 gap-x-24 text-white">
      <div className="w-[571px] ml-[40%] xs:ml-[32%] xs:max-w-min">
        <SlashTextBox
          title={'Problem'}
          slashText={'WHY CHOOSE US'}
          description={`Companies that employ engineers in test and measurement
        roles need individuals with specific skillsets. Those skillsets are not
        easy to recruit nor develop internally.`}
        />
      </div>
      <div></div>
      <div className="ml-[40%] xs:ml-[32%]">
        <CostTable />
        <div className="mt-10">
          <Link to="/services">
            <ArrowButton title={'SERVICES'} />
          </Link>
        </div>
      </div>
      <div className="ml-10 w-[571px] xs:ml-[32%] xs:max-w-fit xs:mt-10 z-30">
        <SlashTextBox
          title={'Solution'}
          slashText={'WHY CHOOSE US'}
          description={`Our talent acquisition and development services save
        organizations an average of $55,000 per new hire in productivity gains
        annually.`}
        />
      </div>
    </div>
  </div>
)

export default WhyChooseUsTable
