import React from 'react'
import { CircleImage, CircleImageProps } from './CircleImage'

export type ScrollItemProps = {
  imageLeft: boolean
  imageProps: CircleImageProps
  title: string
  text: Array<string> // needs to be array so we can make multiple lines
}

/**
 * Determines the ordering the image and text
 *
 * @param {boolean} imageLeft
 * @return {*}  {string}
 */
function isImageLeft(imageLeft: boolean): string {
  if (imageLeft) {
    return 'flex-row xs:flex-col'
  }
  return 'flex-row-reverse xs:flex-col'
}

/**
 *
 * An item in the Scroll scroll widget
 *
 * @return {*}  {JSX.Element}
 */
const ScrollItem = ({
  imageLeft,
  imageProps,
  title,
  text,
}: ScrollItemProps): JSX.Element => (
  <div
    className={`flex ${isImageLeft(
      imageLeft
    )} items-center h-fit w-fit gap-x-28 xs:gap-y-20`}
  >
    <div className="flex-none xs:mt-28 xs:-ml-16">
      <CircleImage
        src={imageProps.src}
        altText={imageProps.altText}
        size={imageProps.size}
        outerCircle={imageProps.outerCircle}
        innerCircle={imageProps.innerCircle}
      />
    </div>
    <div className="leading-loose">
      <div className="text-dark-blue text-5xl font-bold mb-10">{title}:</div>
      <div className="text-black text-lg font-normal w-[444px] xs:text-xl">
        {text.map((t) => (
          <div key={t} className="mb-10">
            {t}
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default ScrollItem
