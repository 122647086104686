import React from 'react'
import CircleImage from '../../../components/CircleImage'

import recruitmentImg from '../../../../assets/images/jpg/stock_photos/ServicesRecuitment.jpg'
import wcuAngle1 from '../../../../assets/images/svg/angles/LandingAnglesPage1.svg'
import bigCheckmark from '../../../../assets/images/svg/decorations/BigCheckmark.svg'

/**
 * Recruitment information to be displayed on the Services page
 *
 * @return {*} {JSX.Element}
 */

const Recruitment = (): JSX.Element => (
  <div>
    <div className="bg-dark-blue flex flex-row justify-center gap-36 outline outline-1 outline-dark-blue xs:flex-col-reverse xs:h-[1500px]">
      <div className="flex flex-col w-1/4 pt-36 xs:pt-10 text-white font-bold xs:ml-[41%] sm:scale-110 xs:scale-150">
        <div className="text-3xl xs:text-5xl pb-8">Recruitment</div>
        <div className="text-2xl text-green pb-8">{`Because of the technical nature of the work test and measurement engineers perform, it costs up to 25%
              of a new engineer’s salary to find the right candidate.`}</div>
        <div className="text-xl font-thin mb-12">{`Our process ensures organizational fit. We source locally and vet education and skills. We strive to
              find candidates that won’t leave in a year.`}</div>
      </div>
      <div className="mb-36 xs:ml-[45%] sm:scale-110 xs:scale-150 z-20">
        <CircleImage
          src={recruitmentImg}
          altText="Two engineers looking at a tablet. They are surrounded by assembly robotics."
          size="large"
          outerCircle={{
            decorations: 'w n s-1/2',
          }}
          innerCircle={false}
        />
      </div>
    </div>
    <div className="relative">
      <img
        src={wcuAngle1}
        alt={'Light blue angled horizontal rule'}
        className="w-full z-10 rotate-180"
      />
      <img
        src={bigCheckmark}
        alt="Really big checkmark"
        className="absolute -top-96 -right-10 z-21 opacity-40 xs:scale-150"
      />
    </div>
  </div>
)

export default Recruitment
