import React from 'react'
import blueHeader from '../../assets/images/svg/angles/HeaderDarkBlueAngle.svg'
import lightHeader from '../../assets/images/svg/angles/HeaderLightBlueAngle.svg'

export type SmallHeaderProps = {
  children: JSX.Element | JSX.Element[]
}

/**
 *
 * Header for non-landing pages.
 *
 * @return {*}  {JSX.Element}
 */
const SmallHeader = ({ children }: SmallHeaderProps): JSX.Element => (
  <div className="min-w-[1440px] w-full text-white z-30">
    <div className="relative overflow-x-clip">
      <div className="absolute min-w-fit h-fit inset-0 bottom-[100px] z-30">
        {children}
      </div>
      <div className="xs:scale-[2.0] xs:left-[30%] xs:top-14">
        <img
          src={lightHeader}
          alt={'Light blue header angle'}
          className="absolute w-[90%] h-auto left-[14.5%] -bottom-[3.5%] z-10 rotate-[.65deg]"
        />
        <img
          src={blueHeader}
          alt={'Dark blue header angle'}
          className="w-full z-0"
        />
      </div>
    </div>
  </div>
)

export default SmallHeader
