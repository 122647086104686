import * as React from 'react'
import { SVGProps } from 'react'

type PatternDecorationProps = {
  svgprops: SVGProps<SVGSVGElement>
  className: string
}

const SvgPatternDecoration = ({
  svgprops,
  className,
}: PatternDecorationProps) => (
  <svg
    width={494}
    height={494}
    fill="none"
    viewBox="0 0 494 494"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    {...svgprops}
  >
    <path
      d="M122.032 141.527c7.306 9.148 11.676 20.767 11.676 33.395 0 29.55-23.888 53.496-53.367 53.496-29.48 0-53.368-23.946-53.368-53.496 0-29.551 23.888-53.497 53.368-53.497a53.062 53.062 0 0 1 33.314 11.704M30.144 116.206C43.64 104.609 61.166 97.608 80.34 97.608M21.767 225.218c-11.57-13.53-18.553-31.097-18.553-50.318 0-19.221 6.984-36.788 18.553-50.318M80.34 252.235c-19.174 0-36.699-7.001-50.196-18.598M130.538 233.615c-13.498 11.597-31.023 18.598-50.197 18.598M138.914 124.604c11.569 13.529 18.554 31.097 18.554 50.318 0 19.221-6.985 36.788-18.554 50.318M80.34 97.608c19.175 0 36.7 7.001 50.198 18.598M413.38 295.917a53.062 53.062 0 0 1 33.314 11.704M455.071 315.997c7.306 9.149 11.676 20.767 11.676 33.395 0 29.551-23.888 53.496-53.367 53.496M363.183 290.677c13.497-11.597 31.022-18.598 50.197-18.598M354.806 399.71c-11.569-13.53-18.553-31.097-18.553-50.318 0-19.221 6.984-36.788 18.553-50.318M413.38 426.705c-19.175 0-36.7-7.001-50.197-18.598"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M463.555 408.107c-13.497 11.597-31.022 18.598-50.197 18.598M471.932 299.095c11.569 13.53 18.553 31.098 18.553 50.319 0 19.221-6.984 36.788-18.553 50.318M413.38 272.079c19.174 0 36.699 7.001 50.197 18.598"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="m476.067 294.95-71.064 71.236-20.953-20.982M123.403 309.425a53.067 53.067 0 0 1 33.315-11.704c29.48 0 53.368 23.946 53.368 53.496 0 29.551-23.888 53.497-53.368 53.497s-53.368-23.946-53.368-53.497a53.357 53.357 0 0 1 11.676-33.395"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M175.271 401.535c-11.569-13.529-18.553-31.097-18.553-50.318 0-19.221 6.984-36.788 18.553-50.318M233.845 428.531c-19.175 0-36.7-7.001-50.197-18.598"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M284.042 409.933c-13.497 11.597-31.022 18.598-50.197 18.598M375.266 299.482c7.306 9.149 11.676 20.767 11.676 33.395 0 29.551-23.888 53.497-53.367 53.497-29.48 0-53.368-23.946-53.368-53.497s23.888-53.497 53.368-53.497a53.057 53.057 0 0 1 33.314 11.705M241.965 411.865c0-19.22 6.984-36.788 18.553-50.318"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M260.518 462.162c-11.569-13.53-18.553-31.097-18.553-50.318M369.289 470.559c-13.498 11.597-31.023 18.598-50.197 18.598-19.175 0-36.7-7.001-50.197-18.598M396.219 411.865c0 19.221-6.984 36.789-18.553 50.319M373.402 474.683l-71.064-71.236M265.724 327.658l-40.256 40.354-15.404-15.441M309.837 23.323a53.355 53.355 0 0 1 11.676 33.395c0 29.55-23.888 53.497-53.368 53.497s-53.368-23.946-53.368-53.497c0-29.55 23.888-53.497 53.368-53.497a53.063 53.063 0 0 1 33.315 11.705M326.697 83.713c11.57 13.53 18.554 31.098 18.554 50.318"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M217.948 75.316c13.498-11.597 31.022-18.598 50.197-18.598 19.175 0 36.7 7.001 50.197 18.598M191.018 134.031c0-19.22 6.984-36.788 18.553-50.318"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M209.571 184.328c-11.569-13.53-18.553-31.097-18.553-50.318M345.272 134.031c0 19.221-6.984 36.789-18.553 50.319"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M319.756 261.255a53.065 53.065 0 0 1-33.315 11.704c-29.479 0-53.367-23.945-53.367-53.496 0-29.551 23.888-53.497 53.367-53.497 29.48 0 53.368 23.946 53.368 53.497a53.351 53.351 0 0 1-11.676 33.395M257.84 278.178c-13.497 11.597-31.022 18.598-50.197 18.598"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
    <path
      d="M266.217 169.166c11.569 13.53 18.553 31.097 18.553 50.318 0 19.221-6.984 36.788-18.553 50.318M207.665 142.171c19.174 0 36.699 7.001 50.197 18.598M157.468 160.769c13.497-11.597 31.022-18.598 50.197-18.598M144.977 273.904l71.065-71.236M291.647 165.988l-40.256-40.354 15.404-15.462"
      stroke={svgprops.stroke}
      strokeWidth={svgprops.strokeWidth}
      strokeMiterlimit={svgprops.strokeWidth}
    />
  </svg>
)

SvgPatternDecoration.defaultProps = {
  svgprops: { stroke: '#CBCBCB', strokeWidth: 3, strokeMiterlimit: 10 },
  className: '',
}

export default SvgPatternDecoration
