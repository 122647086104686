import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

/**
 * Used for sending events to Google Analytics
 *
 * Every time the location changes (in React Router), information is sent to
 * Google Analytics.
 */
const usePageTracking = (): void => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location])
}

export default usePageTracking
