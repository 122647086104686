import React from 'react'
import LandingPage from './Landing'
import About from './About'
import Services from './Services'
import ContactUs from './ContactUs'
import { Routes, Route } from 'react-router-dom'
import usePageTracking from '../utils/pageTracking'
import ScrollToTop from '../utils/scrollToTop'

/**
 * The main component that bootstraps the website.
 *
 * @return {*}
 */
function TestPointAcademy(): JSX.Element {
  usePageTracking()

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<ContactUs />} />
      </Routes>
    </ScrollToTop>
  )
}

export default TestPointAcademy
