import * as React from 'react'
import { SVGProps } from 'react'

type InnerDecorationProps = {
  svgprops: SVGProps<SVGSVGElement>
  className: string
}

/**
 * Component used for decorating circle images.
 * @param {SVGProps<SVGSVGElement>} {
 *    props,
 * }
 * @return {*} {JSX.Element}
 */
function SvgInnerCircleDecoration({
  svgprops,
  className,
}: InnerDecorationProps) {
  return (
    <svg
      width={512}
      height={512}
      fill="#B9E5FB"
      viewBox="0 0 512 512"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...svgprops}
    >
      <path
        d="M352.476 372.177C299.31 425.343 212.767 425.321 159.574 372.127 106.38 318.934 106.358 232.39 159.524 179.224 181.356 157.392 210.268 143.447 240.8 140.018L244.042 169.945C219.779 172.769 197.943 183.278 180.558 200.663 139.117 242.105 139.135 309.641 180.597 351.103 222.06 392.566 289.798 392.786 331.239 351.344 372.681 309.903 372.865 242.165 331.2 200.5 314.009 183.308 291.966 172.586 267.7 169.748L270.928 139.823C301.462 143.268 330.381 157.228 352.224 179.072 405.418 232.265 405.44 318.809 352.274 371.975L352.476 372.177Z"
        fill={svgprops.fill}
      />
    </svg>
  )
}

SvgInnerCircleDecoration.defaultProps = {
  svgprops: { fill: '#B9E5FB' },
  className: '',
}

export default SvgInnerCircleDecoration
