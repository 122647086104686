import React from 'react'
import SlashTextBox from '../../../components/SlashTextBox'
import CircleImage from '../../../components/CircleImage'

import approachImg from '../../../../assets/images/jpg/stock_photos/ServicesApproachOutcomes.jpg'

const SlashTextsWithImage = (): JSX.Element => (
  <div className="flex justify-center xs:mb-72">
    <div className="justify-center grid grid-cols-2 xs:grid-cols-1 w-[75%] pt-16 xs:mt-80 sm:scale-110 xs:scale-150">
      <div className="text-dark-blue xs:ml-[36%] xs:max-w-[32%]">
        <SlashTextBox
          slashText="SERVICES"
          slashColor="text-dark-gray"
          description={`We establish the global standard for test and measurement engineering by integrating best
          practices in logistics, softare development, and hardware production.`}
          descriptionColor="text-black"
          title="Approach"
        />
        <div className="mt-20">
          <SlashTextBox
            slashText=""
            description={`We create the best engineers through world-class talent development.
                        Our learners consistently design and lead projects after engaging with us for 12 months.`}
            descriptionColor="text-black"
            title="Outcomes"
          />
        </div>
      </div>
      <div className="ml-44 xs:ml-[34%] xs:mt-20 xs:mb-48 xs:scale-110">
        <CircleImage
          src={approachImg}
          altText="Three engineers gathered around a laptop with testing equipment around them."
          size="large"
          outerCircle={{ decorations: 'n s' }}
          innerCircle={true}
        />
      </div>
    </div>
  </div>
)

export default SlashTextsWithImage
