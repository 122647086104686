import React from 'react'
import WeCanHelpImageAndBullets from '../../../components/WeCanHelpImageAndBullets'
import wcuAngle1 from '../../../../assets/images/svg/angles/LandingAnglesPage1.svg'
import circles from '../../../../assets/images/svg/decorations/LandingCircles2.svg'

/**
 * We can help section of Landing page.
 *
 * @return {*}  {JSX.Element}
 */
const WeCanHelp = (): JSX.Element => (
  <div className="relative w-full">
    <div className="bg-gray w-full h-[800px] xs:h-[2100px] bg-opacity-20">
      <WeCanHelpImageAndBullets />
    </div>
    <img
      src={circles}
      alt={'White circle decorations'}
      className="absolute right-0 bottom-48 z-0"
    />
    <img
      src={wcuAngle1}
      alt={'Blue angled horizontal rule'}
      className="bg-gray w-full bg-opacity-20 z-10"
    />
  </div>
)

export default WeCanHelp
