import React from 'react'
import { useMediaQuery } from 'react-responsive'
import stairs from '../../../../assets/images/svg/decorations/StairsGraphic.svg'
import StairPointsXs from './StairStepperPointsXS'
import StairPoints from './StairStepperPoints'

const StairStepper = (): JSX.Element => {
  const isXsScreenSize = useMediaQuery({ maxWidth: 450 })

  return (
    <div className="flex justify-center w-full min-w-[1440px] xs:scale-[3] sm:scale-[1.25] xs:mb-[1800px] sm:mb-[10%]">
      <div className="relative w-[56rem] sm:mt-48">
        <div className="xs:h-64"></div>
        <div className="mt-16 xs:mt-48 xs:ml-[42.7%] xs:scale-[1.3] xs:w-full sm:ml-[10%]">
          <img
            src={stairs}
            alt="A simple graphic of a person climbing a set of stairs."
          />
        </div>
        {isXsScreenSize ? <StairPointsXs /> : <StairPoints />}
      </div>
    </div>
  )
}

export default StairStepper
