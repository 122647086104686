import React from 'react'

const StairPointsXs = (): JSX.Element => (
  <div className="grid grid-rows-3 grid-cols-2 grid-flow-col gap-y-4 gap-x-12 ml-[270px] mt-32 max-w-sm">
    <div className="text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 2</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Introduction</li>
        <li>Learning</li>
      </ol>
    </div>
    <div className="text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 6</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Contributing</li>
        <li>Assignments</li>
        <li>*Proficient</li>
      </ol>
    </div>
    <div className="text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 10</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Decision Making</li>
      </ol>
    </div>
    <div className="text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 4</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Small Tasks</li>
        <li>Shadowing</li>
      </ol>
    </div>
    <div className="text-dark-blue ">
      <h1 className="font-bold text-2xl">Month 8</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Individual Projects</li>
      </ol>
    </div>
    <div className=" text-dark-blue">
      <h1 className="font-bold text-2xl">Month 12</h1>
      <ol className="list-disc list-inside ml-8">
        <li>Designing</li>
        <li>Leading</li>
      </ol>
    </div>
  </div>
)

export default StairPointsXs
