import React from 'react'
import NavLink from './NavLink'
import drawerAngle from '../../assets/images/svg/mobile/Rectangle 86.svg'

/**
 *
 * Navigation links on a page
 *
 * @return {*}  {JSX.Element}
 */
const NavDrawerMenu = (): JSX.Element => (
  <div className="xs:min-w-full">
    <div className="absolute flex flex-col right-[30%] xs:right-[37%] top-[100%] xs:top-32 z-50 h-48 scale-[1.65] xs:scale-150">
      <NavLink isHeader={true} navigateTo="" label="HOME" />
      <NavLink isHeader={true} navigateTo="about" label="ABOUT" />
      <NavLink isHeader={true} navigateTo="services" label="SERVICES" />
      <NavLink isHeader={true} navigateTo="contact" label="CONTACT US" />
    </div>
    <img
      src={drawerAngle}
      alt={'Blue angled shape'}
      className="md:hidden absolute sm:right-[10%] xs:right-[15%] top-0 z-40 w-[35%] xs:w-[35%] h-auto min-w-[350px]"
    />
  </div>
)

export default NavDrawerMenu
