import React from 'react'
import HeaderImg from '../../../components/HeaderImg'
import HeaderNav from '../../../components/HeaderNav'
import SlashTextBox from '../../../components/SlashTextBox'

/**
 * The full header containing the hero image an nav bar.
 *
 * @return {*}  {JSX.Element}
 */
const LandingHeader = (): JSX.Element => (
  <div className="min-w-[1440px] w-full h-fit">
    <HeaderImg>
      <HeaderNav />
      <div className="ml-[20%] xs:ml-[25%] max-w-sm sm:max-w-min xs:max-w-min xs:scale-150 xs:mt-[8%]">
        <SlashTextBox
          slashText="WHAT WE DO"
          description={`We solve engineering resource problems through
          world-class talent development.`}
          title="Creating the best engineers"
        />
      </div>
    </HeaderImg>
  </div>
)

export default LandingHeader
