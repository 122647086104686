import React from 'react'
import whiteAngle from '../../assets/images/svg/angles/LandingWhiteHeader.svg'
import blueAngle from '../../assets/images/svg/angles/LandingBlueAngle.svg'

type HeaderImgProps = {
  children: JSX.Element | JSX.Element[]
}

const HeaderImg = ({ children }: HeaderImgProps): JSX.Element => (
  <div className="min-w-[1440px] h-[1000px] text-white bg-landing bg-cover bg-bottom bg-no-repeat">
    <div className="flex flex-col w-full h-[1001px] justify-between">
      <div className="z-10">{children}</div>
      <div className="relative w-full">
        <img
          src={blueAngle}
          alt="Light blue angled divider"
          className="absolute w-full h-auto left-[14%] bottom-[32%] mix-blend-overlay opacity-80 z-5"
        />
        <img
          src={whiteAngle}
          alt="White diamond divider"
          className="w-full h-full z-15"
        />
      </div>
    </div>
  </div>
)

export default HeaderImg
