import React from 'react'
import HeaderNav from '../components/HeaderNav'
import Footer from '../components/Footer'
import { OurPeopleSection } from '../components/OurPeopleSection'
import ActionDescription from '../components/ActionDescription'

import aboutUsImg from '../../assets/images/jpg/stock_photos/AboutOurStory.jpg'
import aboutAngle1 from '../../assets/images/svg/angles/AboutAnglesPage1.svg'
import SmallHeader from '../components/SmallHeader'

/**
 * The About Page
 *
 * @return {*}  {JSX.Element}
 */
function About(): JSX.Element {
  document.title = 'About Us'
  return (
    <div className="content-page">
      <SmallHeader>
        <HeaderNav />
      </SmallHeader>
      <div className="w-full ml-[50%] xs:mt-72 xs:my-48 xs:ml-[95%] xs:flex xs:flex-col sm:my-10 xs:gap-x-48 sm:scale-110 xs:scale-125">
        <ActionDescription
          imageProps={{
            src: aboutUsImg,
            altText:
              'Serveral people sitting around a desk with laptops in front of them.',
            size: 'large',
            outerCircle: { decorations: 'w s e' },
            innerCircle: false,
          }}
          slashBoxProps={{
            slashText: 'ABOUT US',
            slashColor: 'text-dark-gray',
            description: `TestPoint Academy was born out of a desire to solve engineering resource problems. While
            Testeract aims to solve the same problem through products and integration services, TestPoint Academy's focus lies
            solely in finding, developing, and mentoring engineers to fill open positions and do the work companies need
            to be successful. If we can't do the work for you, we will teach you how to do the work yourself.`,
            title: 'Our Story',
            titleColor: 'text-dark-blue',
          }}
          rightSideImg={true}
          className="w-1/2"
        />
      </div>
      <div className="min-w-[1440px] w-full">
        <img
          className="w-full"
          src={aboutAngle1}
          alt={'Angled horizontal rule'}
        />
        <div className="flex justify-center bg-sky-blue">
          <div className="w-3/4 xs:h-auto">
            <OurPeopleSection />
          </div>
        </div>
      </div>
      <div className="w-full z-40">
        <Footer />
      </div>
    </div>
  )
}

export default About
