import React, { useState, useEffect } from 'react'
import NavLinks from './NavLinks'
import MenuButton from '../../assets/images/svg/mobile/MenuLines.svg'
import close from '../../assets/images/svg/mobile/X close.svg'
import NavDrawerMenu from './NavDrawerMenu'

type NavLinkProps = {
  isHeader: boolean
}

//one pixel less than the breakpoint for medium screens. Needs to not be hardcoded in the future
const MEDIUM_SCREEN_WIDTH = 767

const NavDrawer = ({ isHeader }: NavLinkProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  //Used to close the drawer when the screen is resized to a larger size so that it does not stay open
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > MEDIUM_SCREEN_WIDTH && isDrawerOpen) {
        toggleDrawerOpen()
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isDrawerOpen])

  return (
    // Section displays header in a normal view like before
    <header className="flex justify-items-end text-white">
      <div className="flex flex-row justify-evenly items-center w-[500px]">
        <nav className="hidden md:flex justify-between space-x-[110px]">
          <NavLinks isHeader={isHeader} />
        </nav>
      </div>
      {/* This button  is the drawer menu and is hidden until the screen becomes smaller*/}
      <button className="md:hidden md:justify-end" onClick={toggleDrawerOpen}>
        <img
          src={MenuButton}
          alt={'Menu Button'}
          className="absolute right-[9%] top-[8%] xs:right-[12.5%] xs:top-[1%] justify-end z-6 sm:w-[9%] xs:w-[18%] min-w-[40px]"
        />
      </button>

      {/* This button is rendered when the drawer is opened*/}
      {isDrawerOpen && (
        <button className="" onClick={toggleDrawerOpen}>
          <img
            src={close}
            alt={'Close Button'}
            className="md:hidden absolute sm:right-[12%] sm:top-[25%] xs:right-[20%] xs:top-[45%] justify-end z-50 sm:w-[1%] xs:w-[5%] h-auto min-w-[40px]"
          />
        </button>
      )}

      {/* This section is what is rendered when the drawer is open*/}
      {isDrawerOpen && (
        <nav className="flex md:hidden w-[500px]">
          <NavDrawerMenu />
        </nav>
      )}
    </header>
  )
}

export default NavDrawer
