import React from 'react'
import FounderFrame from '../../../components/FounderFrame'
import Circles from '../../../components/svg/SvgPatternDecoration'

/**
 * Quote from Sam at the bottom of the Landing page.
 *
 * @return {*}  {JSX.Element}
 */
const FounderQuote = (): JSX.Element => (
  <div className="relative w-full mt-[100px]">
    <FounderFrame />
    <Circles
      svgprops={{ stroke: '#D9D8D6', strokeWidth: 2 }}
      className="absolute z-0 right-0 bottom-10 opacity-40 h-[764px]"
    />
  </div>
)

export default FounderQuote
