/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import CircleImage from './CircleImage'
import ArrowButton from './ArrowButton'
import SvgTriangle from './svg/SvgTriangle'

export type OurPeopleModalProps = {
  imgSrc: string
  name: string
  description: string
  link: string
}

/**
 *
 * @returns
 */
export function OurPeopleModal({
  imgSrc,
  name,
  description,
  link,
}: OurPeopleModalProps): JSX.Element {
  const [showModal, setShowModal] = React.useState(false)
  const [firstName] = name.split(' ')
  const altText = 'A headshot of TestPoint Academy Mentor, ' + name + '.'
  const linkLabel = name + "'s LinkedIn"
  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <div className="grid grid-cols-2 relative w-fit">
          <div className="absolute top-8 left-20 mr-4 w-48 flex flex-row ">
            <span className="justify-between bg-light-blue text-dark-blue text-sm font-bold tracking-[0.02em] p-2 pr-16 w-full bg-opacity-25">
              <span className="opacity-100">{firstName.toUpperCase()}</span>
              <div className="absolute top-0.5 right-4 opacity-40">
                <SvgTriangle fill="#FFFFFF" transform="scale(1, .5)" />
              </div>
            </span>
          </div>
          <CircleImage
            size="small"
            src={imgSrc}
            altText={altText}
            outerCircle={{ decorations: '' }}
            innerCircle={false}
          />
        </div>
      </button>
      {showModal ? (
        <>
          <div
            onClick={() => setShowModal(false)}
            onKeyDown={() => setShowModal(false)}
            role="button"
            tabIndex={0}
            className="cursor-default justify-center items-center flex sm:overflow-x-hidden md:overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-auto my-6 mx-auto max-w-3xl">
              <div className="shadow-lg relative flex w-96 min-h-[24rem] h-auto our-people-diagonal">
                <div className="absolute top-8 -left-28">
                  <CircleImage
                    size="medium"
                    src={imgSrc}
                    altText={altText}
                    outerCircle={{ decorations: '' }}
                    innerCircle={false}
                  />
                </div>
                <div className="p-8 text-left text-white text-sm mb-16 ml-16">
                  <h3 className="text-3xl font-semibold text-white pb-8">
                    {name}
                  </h3>
                  {description}
                </div>
                <div className="absolute top-2 -right-4 drop-shadow-2xl">
                  <button
                    className="bg-light-blue rounded-full border-0 text-black"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="h-8 w-8 text-2xl text-white block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                <div className="absolute w-auto bottom-8 -left-8 z-100">
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Open more information."
                  >
                    <div className="absolute w-96 bottom-4 left-12">
                      <ArrowButton title={linkLabel} />
                    </div>
                    <div className="bg-green rounded-full w-16 h-16 drop-shadow-2xl">
                      <i className="text-2xl fab fa-linkedin pt-4 pl-5" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black xs:-inset-full"></div>
        </>
      ) : null}
    </>
  )
}

export default OurPeopleModal
