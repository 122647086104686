import React from 'react'

export type SlashTextBoxProps = {
  title?: string
  titleColor?: string
  slashText: string
  slashColor?: string
  description: string
  descriptionColor?: string
}

/**
 *
 * Text boxes with / <Text> and a bold title, normal description.
 *
 * @param {SlashTextBoxProps} {
 *   slashText,
 *   description,
 *   title,
 * }
 * @return {*}  {JSX.Element}
 */
export function SlashTextBox({
  title,
  titleColor,
  slashText,
  slashColor,
  description,
  descriptionColor,
}: SlashTextBoxProps): JSX.Element {
  const slash = slashText === '' ? '' : '/ ' + slashText
  return (
    <>
      <div className={`text-[12px] font-bold italic mb-[10px] ${slashColor}`}>
        {slash}
      </div>
      <div className={`text-5xl font-semibold ${titleColor}`}>{title}</div>
      <div className={`text-lg mt-5 ${descriptionColor}`}>{description}</div>
    </>
  )
}

export default SlashTextBox
