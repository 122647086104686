import React from 'react'
import HeaderNav from '../components/HeaderNav'
import Footer from '../components/Footer'
import SmallHeader from '../components/SmallHeader'
import ContactUsTable from '../components/ContactUsTable'
import WriteUs from '../components/WriteUs'

/**
 * Contact Us page
 *
 * @return {*}  {JSX.Element}
 */
const ContactUs = (): JSX.Element => {
  document.title = 'Contact Us'
  return (
    <div className="content-page">
      <SmallHeader>
        <HeaderNav />
      </SmallHeader>
      <div className="w-full flex flex-row items-baseline justify-center gap-10 contact-diagonal">
        <ContactUsTable />
        <WriteUs />
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs
