import React from 'react'
import TestPointLogo from '../../assets/images/svg/branding/testpoint_academy_full_logo.svg'

const CostTable = (): JSX.Element => (
  <div className="w-[522px] h-[400px] grid grid-cols-3 bg-transparent">
    <div className="cost-table-text-cell">
      <span className="text-white text-[28px] font-bold">Costs</span>
    </div>
    <div className="cost-table-text-cell">
      <img
        src={TestPointLogo}
        alt="TestPoint Academy Logo"
        className="w-[150px] pl-[20px] pt-[20px]"
      />
    </div>
    <div className="cost-table-text-cell pt-[60px]">
      <span className="cost-table-text-sm-span">Internal to company</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-text-sm-span">Productivity Loss</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-green-cell">$0</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-gray-cell">$59,392</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-text-sm-span">Mentoring</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-green-cell">$0</span>
      <span className="text-green text-sm font-semibold"> (included)</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-gray-cell">$25,400</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-text-sm-span">Training</span>
      <br />
      <span className="cost-table-text-sm-span">(per month)</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-green-cell">$5,500</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-gray-cell">$2,427</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-text-sm-span">Recruiting</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-green-cell">$20,000</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-number-gray-cell">$20,000</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="cost-table-text-sm-span">Total: 12 Months</span>
    </div>
    <div className="cost-table-text-cell">
      <span className="text-green text-[28px] font-bold pl-[20px]">
        $86,000
      </span>
    </div>
    <div className="cost-table-text-cell">
      <span className="text-orange text-[28px] font-bold pl-[20px]">
        $133,916
      </span>
    </div>
  </div>
)

export default CostTable
