import React from 'react'

import ActionDescription from '../../../components/ActionDescription'
import CircleImage from '../../../components/CircleImage'

import mentoring from '../../../../assets/images/jpg/stock_photos/ServicesMentoring.jpg'
import consulting from '../../../../assets/images/jpg/stock_photos/ServicesConsulting.jpg'

const MentoringConsulting = () => (
  <div className="flex flex-col bg-sky-blue">
    <div className="pl-36 pt-24 w-full flex flex-row">
      <CircleImage
        src={mentoring}
        altText="Two engineers studying a piece of hardware in one of the engineer's hands. A laptop in on a table in the foreground."
        size="large"
        outerCircle={{ decorations: 'n w s-1/2' }}
        innerCircle={false}
      />
      <div className="pl-24 w-2/5 [&>*]:pb-8">
        <div className="text-5xl text-dark-blue font-bold">Mentoring</div>
        <ul className="list-disc list-outside text-lg">
          <li>
            Our mentors are experts in their respective fields with decades of
            experience.
          </li>
          <li>
            Each student is assigned a dedicated mentor and has access to other
            experts depending on need. We have mentors ready to consult on best
            practices pertaining to hardware, software, RF, etc.
          </li>
          <li>Mentors are great at Peer Reviews!</li>
        </ul>
      </div>
    </div>
    <div className="w-2/3 p-36">
      <ActionDescription
        imageProps={{
          src: consulting,
          altText: 'Four people looking at and drawing on a large white board',
          size: 'large',
          outerCircle: {
            decorations: 'n w s-1/2',
          },
          innerCircle: false,
        }}
        slashBoxProps={{
          slashText: '',
          title: 'Consulting',
          titleColor: 'text-dark-blue',
          descriptionColor: 'text-black',
          description:
            'Utilize our proprietary assessment to identify areas to strengthen your team dynamics and improve the outcomes of your test organization.',
        }}
        rightSideImg={true}
      />
    </div>
  </div>
)

export default MentoringConsulting
