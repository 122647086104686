import React from 'react'
import YouTube from 'react-youtube'

const Training = (): JSX.Element => (
  <div className="flex justify-center w-full">
    <div className="">
      <div className="text-3xl text-dark-blue font-bold pb-16">Training</div>
      <YouTube
        videoId="I-U-7WPHFMg"
        opts={{
          height: '360',
          width: '480',
        }}
        title="TestPoint Academy"
      />
    </div>
    <ul className="list-disc list-outside text-lg font-thin mt-24 ml-36 w-[25%] [&>*]:pb-8">
      {/* Applies the pb-8 to all the children elements */}
      <li>We have experts in adult education and curriculum development.</li>
      <li>
        We tailor our programs to meet company culture and work expectations.
      </li>
      <li>
        Students participate in 160 hours of learning and practical application.
      </li>
      <li>
        We create an environment where learning is immediately applicable on the
        job through modeling and scaffolding.
      </li>
      <li>We partner with your organization to achieve together.</li>
    </ul>
  </div>
)

export default Training
