import React from 'react'
import { SlashTextBox, SlashTextBoxProps } from './SlashTextBox'
import { ArrowButton, ArrowButtonProps } from './ArrowButton'
import { CircleImage, CircleImageProps } from './CircleImage'
import { Link } from 'react-router-dom'

type ActionDescriptionProps = {
  slashBoxProps?: SlashTextBoxProps
  buttonProps?: ArrowButtonProps
  buttonLink?: string
  imageProps?: CircleImageProps
  className?: string
  rightSideImg?: boolean
}

/**
 *
 * @param buttonProps
 * @param buttonLink
 * @return {*}
 */
function getButton(buttonProps?: ArrowButtonProps, buttonLink?: string) {
  return buttonLink && buttonProps ? (
    <Link className="pt-3" to={buttonLink}>
      <ArrowButton title={buttonProps.title} />
    </Link>
  ) : (
    <>{buttonProps && <ArrowButton title={buttonProps.title} />}</>
  )
}

/**
 * Combo of a slash box Description, CircleImage, and Button
 * @param {ActionDescriptionProps}
 * slashBoxProps
 * buttonProps
 * imageProps
 * className
 * rightSideImg Used to flip which side the image is on
 * @return {*}
 */
function ActionDescription({
  slashBoxProps,
  buttonProps,
  buttonLink,
  imageProps,
  className,
  rightSideImg = false,
}: ActionDescriptionProps): JSX.Element {
  const elementOrder = rightSideImg
    ? 'flex flex-row-reverse xs:flex-col-reverse sm:my-20 xs:max-w-min gap-12 xs:gap-36 items-center'
    : 'flex xs:flex-col xs:gap-10 sm:gap-20 gap-12 flex-row items-center'
  return (
    <div className={className}>
      <div className={elementOrder}>
        <div className="flex-none xs:min-w-fit xs:max-w-min sm:scale-110 xs:scale-125">
          {imageProps && (
            <CircleImage
              src={imageProps.src}
              altText={imageProps.altText}
              size={imageProps.size}
              outerCircle={imageProps.outerCircle}
              innerCircle={imageProps.innerCircle}
            />
          )}
        </div>
        <div className="flex flex-col xs:min-w-[300px] xs:max-w-[500px] sm:scale-110 xs:scale-[1.35]">
          {slashBoxProps && (
            <SlashTextBox
              title={slashBoxProps.title}
              titleColor={slashBoxProps.titleColor}
              slashText={slashBoxProps.slashText}
              slashColor={slashBoxProps.slashColor}
              description={slashBoxProps.description}
              descriptionColor={slashBoxProps.descriptionColor}
            />
          )}
          <div className="grow" />
          {getButton(buttonProps, buttonLink)}
        </div>
      </div>
    </div>
  )
}
export default ActionDescription
