import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

type ScrollToTopProps = {
  children: React.ReactElement | React.ReactElement[]
}

/**
 * Every time the location changes, the scroll will be reset to the top of the
 * page.
 *
 * @param {ScrollToTopProps} { children }
 * @return {*}  {JSX.Element}
 */
const ScrollToTop = ({ children }: ScrollToTopProps): JSX.Element => {
  const location = useLocation()
  const origin = 0
  useEffect(() => {
    window.scrollTo(origin, origin)
  }, [location])

  return <>{children}</>
}

export default ScrollToTop
