import React from 'react'
import Footer from '../components/Footer'
import Header from './pageElements/Landing/Header'
import WhoWeAre from './pageElements/Landing/WhoWeAre'
import WeCanHelp from './pageElements/Landing/WeCanHelp'
import WhyChooseUs from './pageElements/Landing/WhyChooseUs'
import StationaryScroll from './pageElements/Landing/StationaryScroll'
import FounderQuote from './pageElements/Landing/FounderQuote'
import ThisIsUs from './pageElements/Landing/ThisIsUs'

/**
 * The Home Page
 *
 * @return {*}  {JSX.Element}
 */
const Landing = (): JSX.Element => {
  document.title = 'TestPoint Academy'
  return (
    <div className="page-container">
      <div className="content-page">
        <Header />
        <WhoWeAre />
        <WeCanHelp />
        <WhyChooseUs />
        <StationaryScroll />
        <ThisIsUs />
        <FounderQuote />
        <Footer />
      </div>
    </div>
  )
}

export default Landing
