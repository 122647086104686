import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={32} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 31.92V0h.16l15.96 15.96L.08 32 0 31.92Z"
    />
  </svg>
)

export default SvgComponent
