import React from 'react'
import Logo from '../../assets/images/svg/branding/testpoint_academy_full_logo.svg'
import NavDrawer from './NavDrawer'
import { Link } from 'react-router-dom'

/**
 *
 * Navigation links on a page
 *
 * @return {*}  {JSX.Element}
 */
const HeaderNav = (): JSX.Element => (
  <div className="relative flex flex-row justify-start ml-[35px] items-center xs:ml-[330px] sm:ml-[200px] sm:scale-125 xs:scale-150">
    <Link to="/" className="w-[200px] min-w-[200px]">
      <img src={Logo} alt="TestPoint Academy Logo" />
    </Link>
    <div className="ml-20 xs:ml-0 sm:ml-0"></div>
    <NavDrawer isHeader />
  </div>
)

export default HeaderNav
