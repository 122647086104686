import React from 'react'

/**
 *
 *
 * @return {*}  {JSX.Element}
 */
const CircleBackground = (): JSX.Element => (
  <div className="circle-background"></div>
)

export default CircleBackground
