import React from 'react'
import HeaderNav from '../components/HeaderNav'
import Footer from '../components/Footer'
import SlashTextsWithImage from './pageElements/Services/SlashTextsWithImage'
import StairStepper from './pageElements/Services/StairStepper'
import Recruitment from './pageElements/Services/Recruitment'
import Training from './pageElements/Services/Training'
import TrainingBottom from './pageElements/Services/TrainingBottom'
import MentoringConsulting from './pageElements/Services/MentoringConsulting'
import SmallHeader from '../components/SmallHeader'
import circles from '../../assets/images/svg/decorations/ServicesCircles1.svg'
import blueAngle from '../../assets/images/svg/angles/LandingAngleBlueTop.svg'
import blueBottom from '../../assets/images/svg/angles/LandingAngleBlueBottom.svg'
import whiteCircles from '../../assets/images/svg/decorations/ServicesWhiteCircles.svg'

/**
 * The Services Page
 *
 * @return {*}  {JSX.Element}
 */
const Services = (): JSX.Element => {
  document.title = 'Services'
  return (
    <div className="content-page">
      <div className="relative w-full services-diagonal">
        <SmallHeader>
          <HeaderNav />
        </SmallHeader>
        <SlashTextsWithImage />
        <StairStepper />
        <img
          src={circles}
          alt={'Gray circle decorations'}
          className="absolute right-0 bottom-[160px] z-10 w-[35%] h-auto min-w-[300px] xs:hidden"
        />
        <img
          src={blueAngle}
          alt={'Blue angled horizontal rule'}
          className="absolute w-full h-auto bg-opacity-0 rotate-180"
        />
        <img
          src={blueBottom}
          alt={'Blue angled horizontal rule'}
          className="w-full relative h-auto bg-opacity-0 rotate-180"
        />
      </div>
      <Recruitment />
      <Training />
      <div className="relative w-full z-20">
        <img
          src={whiteCircles}
          alt={'white circle decorations'}
          className="absolute -right-10 top-96 z-0 w-[30%] h-auto min-w-[300px]"
        />
        <TrainingBottom />
        <MentoringConsulting />
      </div>
      <Footer />
    </div>
  )
}

export default Services
