import React from 'react'
import ScrollItem from '../../../components/ScrollItem'
import { CircleImageProps } from '../../../components/CircleImage'

import whiteAngle from '../../../../assets/images/svg/angles/LandingWhiteAngle1.svg'
import CircleBackground from '../../../components/CircleBackground'
import DiagonalBackground from '../../../components/DiagonalBackground'
import TopAngle from '../../../../assets/images/svg/angles/LandingAnglesPage2.svg'
import scroll1 from '../../../../assets/images/jpg/stock_photos/LandingParallaxPartnership.jpg'
import scroll2 from '../../../../assets/images/jpg/stock_photos/LandingParallaxIntegrity.jpg'
import scroll3 from '../../../../assets/images/jpg/stock_photos/LandingParallaxProficiency.jpg'
import scroll4 from '../../../../assets/images/jpg/stock_photos/LandingParallaxResults.jpg'

/**
 *
 * Stationary scrolling section with Partnership, Integrity, Proficiency and
 * Results (piper)
 *
 * @return {*}  {JSX.Element}
 */
const StationaryScroll = (): JSX.Element => (
  <div className="bg-sky-blue w-full">
    <img
      src={TopAngle}
      alt={'Light blue angled horizontal rule'}
      className="relative w-full z-10 scale-svg" //Added the bg-sky-blue to this
    />
    <div className="relative w-full xs:h-[3800px]">
      <div className="z-2 absolute -top-[700px] -right-[500px] rotate-45">
        <DiagonalBackground />
      </div>
      <div className="w-full grid grid-cols-1 gap-[100px] justify-center -mt-28 xs:mt-10">
        <div className="flex w-fit ml-[20%] xs:ml-[40%] z-10 mt-20 xs:flex-col sm:scale-110 xs:scale-125">
          <ScrollItem
            imageLeft={false}
            imageProps={
              {
                src: scroll1,
                altText: 'Three men laughing in a library.',
                size: 'large',
                outerCircle: { decorations: 'nwse' },
                innerCircle: false,
              } as CircleImageProps
            }
            title={'Partnership'}
            text={['Achieving together, we empower your employees to succeed.']}
          />
        </div>
        <div className="z-2 absolute -top-44 left-10">
          <CircleBackground />
        </div>
        <div className="w-fit ml-[20%] xs:ml-[40%] xs:mt-20 z-10 sm:scale-110 xs:scale-125">
          <ScrollItem
            imageLeft={true}
            imageProps={
              {
                src: scroll2,
                altText: 'A woman smiling in a lab.',
                size: 'large',
                outerCircle: { decorations: 'nwse' },
                innerCircle: false,
              } as CircleImageProps
            }
            title={'Integrity'}
            text={[
              'Our people, products and services are trustworthy.',
              "We don't cut corners.",
            ]}
          />
        </div>
        <div className="z-2 absolute bottom-52 -right-10">
          <CircleBackground />
        </div>
        <div className="w-fit ml-[20%] xs:ml-[40%] z-10 sm:scale-110 xs:scale-125">
          <ScrollItem
            imageLeft={false}
            imageProps={
              {
                src: scroll3,
                altText: 'A man standing in a field.',
                size: 'large',
                outerCircle: { decorations: 'nwse' },
                innerCircle: false,
              } as CircleImageProps
            }
            title={'Proficiency'}
            text={[
              'We enable growth, measure competency, and demonstrate mastery through on-the-job performance.',
            ]}
          />
        </div>
        <div className="z-2 absolute -bottom-96 left-10">
          <CircleBackground />
        </div>
        <div className="w-fit ml-[20%] xs:ml-[40%] z-10 sm:scale-110 xs:scale-125">
          <ScrollItem
            imageLeft={true}
            imageProps={
              {
                src: scroll4,
                altText:
                  'A man standing talking on the phone while typing on a keyboard.',
                size: 'large',
                outerCircle: { decorations: 'nwse' },
                innerCircle: false,
              } as CircleImageProps
            }
            title={'Results'}
            text={[
              'Our students meet or exceed work expectations consistently.',
            ]}
          />
        </div>
      </div>
    </div>
    <img
      src={whiteAngle}
      alt={'White angled horizontal rule'}
      className="w-full bottom-0 z-10" //changed to relative instead of absolute
    />
  </div>
)
export default StationaryScroll
