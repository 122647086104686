import React from 'react'
import NavLinks from './NavLinks'
import { Link } from 'react-router-dom'

import CheckMark from '../../assets/images/svg/branding/TestPointMark.svg'

/**
 *
 * Lower gutter content on a Page
 *
 * @return {*}  {JSX.Element}
 */
const Footer = (): JSX.Element => (
  <div className="flex items-center flex-row justify-between w-full mt-5 mb-5 xs:mb-20 xs:z-20 xs:justify-center xs:bg-white xs:mt-20 xs:space-y-5 xs:flex-col xs:h-[200px] xs:scale-150">
    <div className="flex items-center gap-60 xs:flex-row xs:gap-48">
      <Link to={'/'} className="w-[70px]">
        <img
          src={CheckMark}
          alt="TestPoint Academy Logo"
          className="max-w-[70px] ml-[30px]"
        />
      </Link>
      <div className="text-2xl">
        <a
          href="https://www.linkedin.com/company/testpoint-academy"
          aria-label="Visit our LinkedIn page."
        >
          <i className="fab fa-linkedin mr-3" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100074862814489"
          aria-label="Visit our Facebook page."
        >
          <i className="fab fa-facebook" />
        </a>
      </div>
    </div>
    <div className="text-sm mx-10 xs:max-w-[200px] xs:mx-0 xs:mr-16">
      Copyright © {new Date().getFullYear()} TestPoint Academy. All rights
      reserved
    </div>
    <div className="mr-[166px] xs:ml-[15%]">
      <NavLinks isHeader={false} />
    </div>
  </div>
)

export default Footer
