import React from 'react'
import ActionDescription from '../../../components/ActionDescription'

/**
 * This Is Us / Industry Experts section of Landing Page
 *
 * @return {*}  {JSX.Element}
 */
const ThisIsUs = (): JSX.Element => (
  <div className="ml-[17.2%] xs:ml-[35%] z-10 xs:mt-32 xs:mb-48 sm:scale-110 xs:scale-125">
    <ActionDescription
      slashBoxProps={{
        slashText: 'THIS IS US',
        slashColor: 'text-dark-gray',
        descriptionColor: 'text-black',
        title: 'Industry Experts',
        description: `We employ the best and brightest engineers to recruit,
      train, mentor, and consult others. Our teams have created the gold
      standard for Test and Measurement Engineering.`,
      }}
      buttonProps={{ title: 'Services' }}
      buttonLink="/services"
      className="w-1/3 text-dark-blue"
    />
  </div>
)

export default ThisIsUs
