import * as React from 'react'
import { SVGProps } from 'react'

type DecorationProps = {
  svgprops: SVGProps<SVGSVGElement>
  decorations: string
  className: string
}

type ElementFunctionProps = {
  decorations: string
  fill?: string
}

/**
 *
 * Function determines if the east element is required
 *
 * @param {ElementFunctionProps} {
 *   decorations,
 * }
 * @return {*}  {JSX.Element}
 */
function getEastElement({ decorations, fill }: ElementFunctionProps) {
  // eslint-disable-next-line no-magic-numbers
  if (decorations.search('e|E') >= 0) {
    return (
      <path
        d="M400.661 380.138 377.79 360.688C402.662 331.513 416.384 294.614 416.384 256 416.384 217.385 402.662 180.487 377.79 151.312L400.661 131.861C430.107 166.471 446.402 210.52 446.402 256 446.402 301.479 430.107 345.528 400.661 380.138Z"
        fill={fill}
      />
    )
  }
  return <></>
}

/**
 *
 * Function determines if the west element is required
 *
 * @param {ElementFunctionProps} {
 *   decorations,
 * }
 * @return {*}  {JSX.Element}
 */
function getWestElement({ decorations, fill }: ElementFunctionProps) {
  // eslint-disable-next-line no-magic-numbers
  if (decorations.search('w|W') >= 0) {
    return (
      <path
        d="M111.34 380.138C81.894 345.528 65.598 301.479 65.598 256 65.598 210.52 81.894 166.471 111.34 131.861L134.211 151.312C109.339 180.487 95.616 217.385 95.616 256 95.616 294.614 109.339 331.513 134.211 360.688L111.34 380.138Z"
        fill={fill}
      />
    )
  }
  return <></>
}

/**
 *
 * Function determines if the north element is required
 * Looks for a potential 1/2
 *
 * @param {ElementFunctionProps} {
 *   decorations,
 * }
 * @return {*}  {JSX.Element}
 */
function getNorthElement({ decorations, fill }: ElementFunctionProps) {
  // eslint-disable-next-line no-magic-numbers
  if (decorations.search('[nN]-1/2') >= 0) {
    return (
      <path
        d="M151.369 133.863 131.928 110.981C166.52 81.519 210.547 65.216 256.003 65.216V95.249C217.694 95.249 180.529 108.979 151.369 133.863Z"
        fill={fill}
      />
    )
  }
  // eslint-disable-next-line no-magic-numbers
  if (decorations.search('n|N') >= 0) {
    return (
      <>
        <path
          d="M151.369 133.863 131.928 110.981C166.52 81.519 210.547 65.216 256.003 65.216V95.249C217.694 95.249 180.529 108.979 151.369 133.863Z"
          fill={fill}
        />
        <path
          d="M360.633 133.863C331.472 108.979 294.593 95.249 255.998 95.249V65.216C301.454 65.216 345.481 81.519 380.073 110.981L360.633 133.863Z"
          fill={fill}
        />
      </>
    )
  }
  return <></>
}

/**
 *
 * Function determines if the south element is required
 * Looks for a potential 1/2
 *
 * @param {ElementFunctionProps} {
 *   decorations,
 * }
 * @return {*}  {JSX.Element}
 */
function getSouthElement({ decorations, fill }: ElementFunctionProps) {
  // eslint-disable-next-line no-magic-numbers
  if (decorations.search('[sS]-1/2') >= 0) {
    return (
      <path
        d="M256.003 446.784C210.547 446.784 166.52 430.481 131.928 401.019L151.369 378.136C180.529 403.021 217.408 416.751 256.003 416.751V446.784Z"
        fill={fill}
      />
    )
  }
  // eslint-disable-next-line no-magic-numbers
  if (decorations.search('s|S') >= 0) {
    return (
      <>
        <path
          d="M256.003 446.784C210.547 446.784 166.52 430.481 131.928 401.019L151.369 378.136C180.529 403.021 217.408 416.751 256.003 416.751V446.784Z"
          fill={fill}
        />
        <path
          d="M255.998 446.784V416.751C294.307 416.751 331.472 403.021 360.633 378.136L380.073 401.019C345.481 430.481 301.454 446.784 255.998 446.784Z"
          fill={fill}
        />
      </>
    )
  }
  return <></>
}

/**
 *
 * Component used for decorating circle images
 *
 * @param {DecorationProps} {
 *   props,
 *   decorations: string - Component looks for any combination of "n n-1/2 s s-1/2 e w" in the
 *                         string to define which portions of the circle are displayed,
 *   className: string - Tailwind attribute passed to the main svg element.
 * }
 * @return {*}  {JSX.Element}
 */
function SvgCircleDecoration({
  svgprops,
  decorations,
  className,
}: DecorationProps) {
  const { fill } = svgprops
  return (
    <svg
      fill="#B9E5FB"
      viewBox="0 0 512 512"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...svgprops}
    >
      {getNorthElement({ decorations, fill })}
      {getSouthElement({ decorations, fill })}
      {getWestElement({ decorations, fill })}
      {getEastElement({ decorations, fill })}
    </svg>
  )
}

SvgCircleDecoration.defaultProps = {
  svgprops: { fill: '#B9E5FB', opacity: '50%' },
  decorations: 'n w s-1/2',
  className: '',
}

export default SvgCircleDecoration
