import * as React from 'react'
import { SVGProps } from 'react'

import SvgCircleDecoration from './svg/SvgCircleDecoration'
import SvgInnerCircleDecoration from './svg/SvgInnerCircleDecoration'

type OuterCircle = {
  decorations: string
  rotation?: string
}

export type CircleImageProps = {
  // eslint-disable-next-line prettier/prettier
  /**
   * The URL for the image to display. Either locally or exernally located.
   */
  src: string

  /**
   * The alt text for the image
   */
  altText: string

  /**
   * Size
   */
  size?: 'small' | 'medium' | 'large'

  /**
   * Decorations for outer circle
   */
  outerCircle: OuterCircle

  /**
   * Props to include inner circle decoration
   */
  innerCircle: boolean
}

type ImageProps = {
  // eslint-disable-next-line prettier/prettier
  /**
   * The URL for the image to display. Either locally or exernally located.
   */
  src: string

  /**
   * The alt text for the image
   */
  altText: string

  /**
   * Decorations for outer circle
   */
  outerCircle: OuterCircle

  /**
   * Props to include inner circle decoration
   */
  innerCircle: boolean
}

/**
 * @param svgprops {SVGProps<SVGSVGElement>}
 * @param className {string}
 * @param outerCircle {OuterCircle}
 *
 * @returns {*}
 */
function getOuterCircle(
  svgprops: SVGProps<SVGSVGElement>,
  className: string,
  outerCircle: OuterCircle
) {
  if (outerCircle.rotation) {
    const props: SVGProps<SVGSVGElement> = svgprops
    // prettier-ignore
    // eslint-disable-next-line prettier/prettier
    props.transform = String(svgprops.transform) + ', rotate(' + outerCircle.rotation + ')'
  }
  return outerCircle ? (
    <SvgCircleDecoration
      svgprops={svgprops}
      className={className}
      decorations={outerCircle.decorations}
    />
  ) : (
    <></>
  )
}

/**
 * @param svgprops {SVGProps<SVGSVGElement>}
 * @param className {string}
 * @param innerCircle {boolean}
 *
 * @returns {*}
 */
function getInnerCircle(
  svgprops: SVGProps<SVGSVGElement>,
  className: string,
  innerCircle: boolean
) {
  return innerCircle ? (
    <SvgInnerCircleDecoration svgprops={svgprops} className={className} />
  ) : (
    <></>
  )
}

/**
 * @param {ImageProps} {
 *  src: string
 *  altText: string
 * }
 * @returns {*} {JSX.Element}
 */
function getSmallImage({ src, altText, outerCircle, innerCircle }: ImageProps) {
  return (
    <div className="relative w-24 h-24">
      <img
        src={src}
        alt={altText}
        className="object-cover drop-shadow-xl rounded-full w-full h-full z-0 xs:z-50"
      />
      {getOuterCircle(
        { opacity: '40%' },
        'absolute z-1 -top-[9px] -left-[9px] w-[115px] h-[115px]',
        outerCircle
      )}
      {getInnerCircle(
        { opacity: '40%' },
        'absolute z-2 -top-[13.5px] -left-[9px] w-[115px] h-[115px]',
        innerCircle
      )}
    </div>
  )
}

/**
 * @param {ImageProps} {
 *  src: string
 *  altText: string
 * }
 * @returns {*} {JSX.Element}
 */
function getMediumImage({
  src,
  altText,
  outerCircle,
  innerCircle,
}: ImageProps) {
  return (
    <div className="relative w-48 h-48">
      <img
        src={src}
        alt={altText}
        className="object-cover drop-shadow-xl rounded-full w-full h-full z-0"
      />
      {getOuterCircle(
        { opacity: '40%' },
        'absolute z-1 -top-[15px] -left-[15px] w-[225px] h-[225px]',
        outerCircle
      )}
      {getInnerCircle(
        { opacity: '40%' },
        'absolute z-2 -top-[15px] -left-[6.5px] w-[207px] h-[207px]',
        innerCircle
      )}
    </div>
  )
}

/**
 * @param {ImageProps} {
 *  src: string
 *  altText: string
 *  decorations: string
 *  innerCircle: boolean
 * }
 * @returns {*} {JSX.Element}
 */
function getLargeImage({ src, altText, outerCircle, innerCircle }: ImageProps) {
  return (
    <div className="relative w-96 h-96">
      <img
        src={src}
        alt={altText}
        className="object-cover drop-shadow-xl rounded-full w-full h-full z-0"
      />
      {getOuterCircle(
        { opacity: '40%' },
        'absolute z-1 -top-[37.5px] -left-[37.5px] w-[460px] h-[460px]',
        outerCircle
      )}
      {getInnerCircle(
        { opacity: '40%' },
        'absolute z-2 -top-[55px] -left-[37.5px] w-[460px] h-[460px]',
        innerCircle
      )}
    </div>
  )
}

/**
 *
 * Circular Image with optional alt text
 *
 * @param {CircleImageProps} {
 * src,
 * altText,
 * size,
 * }
 *
 * @return {*} {JSX.Element}
 */
export function CircleImage({
  src,
  altText,
  size,
  outerCircle,
  innerCircle,
}: CircleImageProps): JSX.Element {
  if (size === 'small') {
    return <>{getSmallImage({ src, altText, outerCircle, innerCircle })}</>
  } else if (size === 'large') {
    return <>{getLargeImage({ src, altText, outerCircle, innerCircle })}</>
  }
  return <>{getMediumImage({ src, altText, outerCircle, innerCircle })}</>
}

export default CircleImage
