import React from 'react'
import BulletPoint from './BulletPoint'
import BulletPointList from './BulletPointList'

import mailIcon from '../../assets/images/svg/icons/Mail.svg'
import telephoneIcon from '../../assets/images/svg/icons/Telephone.svg'
import linkedInIcon from '../../assets/images/svg/icons/LinkedIn.svg'

/**
 *
 * ContactUs table on left side of Contact Us page.
 *
 * @return {*}  {JSX.Element}
 */
const ContactUsTable = (): JSX.Element => (
  <div className="w-fit">
    <div className="w-[558px] h-[60px] text-5xl font-bold text-dark-blue leading-loose mb-8">
      Contact Us
    </div>
    <BulletPointList>
      <BulletPoint
        icon={mailIcon}
        iconAlt={'e-mail icon'}
        description={'info@testpointacademy.com'}
        buttonText="Email Us"
        buttonLink="mailto:info@testpointacademy.com"
      />
      <BulletPoint
        icon={telephoneIcon}
        iconAlt={'telephone icon'}
        description={'+1 (435) 266-4288'}
        buttonText="Call Us"
        buttonLink="tel:14352664288"
      />
      <BulletPoint
        icon={linkedInIcon}
        iconAlt={'linkedin icon'}
        description={'Connect with us'}
        buttonText="Find us on LinkedIn"
        buttonLink="https://www.linkedin.com/company/testpoint-academy"
      />
    </BulletPointList>
  </div>
)

export default ContactUsTable
