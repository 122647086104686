import React from 'react'

/**
 *
 *
 * @return {*}  {JSX.Element}
 */
const DiagonalBackground = (): JSX.Element => (
  <div className="diagonal-background"></div>
)

export default DiagonalBackground
