import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'
import TestPointAcademy from './pages/TestPointAcademy'
import reportWebVitals from './utils/reportWebVitals'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-KSR072RBPD', {
  legacyDimensionMetric: false,
})

import './tailwind.output.css'

/*
 * This is the bootstrap file for standing up the React website.
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <HubspotProvider>
      <BrowserRouter>
        <TestPointAcademy />
      </BrowserRouter>
    </HubspotProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log)
