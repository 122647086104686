import React from 'react'
import SvgTriangle from './svg/SvgTriangle'

export type ArrowButtonProps = {
  title: string
  isBlue?: boolean
}

/**
 * Using this function so Tailwind can pick up the color codes in the text, use
 * this function in cases where you need the tailwind command and not the hex
 * code.
 *
 * @param {boolean} isBlue
 * @return {*}  {string}
 */
function getBackgroundColor(isBlue: boolean): string {
  if (isBlue) {
    return 'bg-button-blue'
  }
  return 'bg-green'
}

/**
 * SVG's won't get the converted hex code from the tailwind command, so we have to
 * supply the hex code directly.
 *
 * I think this is happening because have to pass these Tailwind commands as
 * props and not as a className.
 *
 * @param {boolean} isBlue
 * @return {*}  {string}
 */
function getBackgroundColorCode(isBlue: boolean): string {
  if (isBlue) {
    return '#C8F1FF'
  }
  return '#00C08A'
}

/**
 * Helper function for choosing the appropiate text color depending on the green
 * or blue background.
 *
 * @param {boolean} isBlue
 * @return {*}  {string}
 */
function getTextColor(isBlue: boolean): string {
  if (isBlue) {
    return 'text-dark-blue'
  }
  return 'text-white'
}

/**
 * Arrow Button used throughout website
 *
 * @return {*}  {JSX.Element}
 */
export const ArrowButton = ({
  title,
  isBlue = false,
}: ArrowButtonProps): JSX.Element => (
  <div className="flex flex-row max-w-fit justify-center items-center">
    <span
      className={`${getBackgroundColor(isBlue)} ${getTextColor(
        isBlue
      )} text-sm font-bold tracking-[0.02em] p-[6px] pl-5 pr-5 whitespace-nowrap`}
    >
      {title.toUpperCase()}
    </span>
    <SvgTriangle fill={getBackgroundColorCode(isBlue)} />
  </div>
)

export default ArrowButton
