import React from 'react'
import SlashTextBox from './SlashTextBox'
import BulletPoint from './BulletPoint'
import BulletPointList from './BulletPointList'
import CircleImage from './CircleImage'

import whiteAngle from '../../assets/images/svg/angles/LandingWhiteAngle2.svg'
import weCanHelpImg from '../../assets/images/jpg/stock_photos/LandingWeCanHelp.jpg'
import recruiting from '../../assets/images/svg/icons/RecruitingIcon.svg'
import training from '../../assets/images/svg/icons/TrainingIcon.svg'
import mentoring from '../../assets/images/svg/icons/MentoringIcon.svg'
import consulting from '../../assets/images/svg/icons/ConsultingIcon.svg'

/**
 *
 * 'We Can Help' section on Landing page
 *
 * @return {*}  {JSX.Element}
 */
const WeCanHelpImageAndBullets = (): JSX.Element => (
  <div className="relative mb-10 z-20">
    <img src={whiteAngle} alt={'White angle divider'} className="w-[100%]" />
    <div className="absolute -top-[160px] xs:top-[175px] xs:flex xs:flex-col grid grid-cols-2 gap-10 sm:scale-110 xs:scale-150">
      <div className="col-span-2 ml-[40%] w-[560px] h-[130px]">
        <SlashTextBox
          title={'TestPoint Academy can help you through:'}
          titleColor={'text-dark-blue'}
          slashText={'WE CAN HELP'}
          slashColor={'text-dark-gray'}
          description={''}
        />
      </div>
      <div className="ml-[40%] xs:ml-[45%]">
        <CircleImage
          src={weCanHelpImg}
          altText={'A man and woman observing a robot arm in lab.'}
          size={'large'}
          outerCircle={{ decorations: 'nw' }}
          innerCircle={false}
        />
      </div>
      <BulletPointList>
        <BulletPoint
          title={'RECRUITING'}
          description={'Let us find the right engineers for your needs.'}
          icon={recruiting}
          iconAlt={'Icon repsentation of a person and magnifying glass.'}
        />
        <BulletPoint
          title={'TRAINING'}
          description={
            'Learn from the best in the business through on-site training and live webinars.'
          }
          icon={training}
          iconAlt={'Icon representation of graduation cap.'}
        />
        <BulletPoint
          title={'MENTORING'}
          description={
            "We're here to ensure you apply your learning on the job, consult on your projects, and conduct peer reviews."
          }
          icon={mentoring}
          iconAlt={
            'Icon representation of two people, one in the forground shaded dark and the second only an outlined traced.'
          }
        />
        <BulletPoint
          title={'CONSULTING'}
          description={
            "Partner with us to assess your team's strength and opportunities for growth."
          }
          icon={consulting}
          iconAlt={
            'Icon representation of two people, one with a speech bubble above them.'
          }
        />
      </BulletPointList>
    </div>
  </div>
)

export default WeCanHelpImageAndBullets
