import React from 'react'
import NavLink from './NavLink'

type NavLinkProps = {
  isHeader: boolean
}

/**
 *
 * Navigation links on a page, can be in the Header or the Footer.
 *
 * @return {*}  {JSX.Element}
 */
const NavLinks = ({ isHeader }: NavLinkProps): JSX.Element => (
  <div className="flex flex-row justify-evenly items-center w-[500px] xs:w-[375px]">
    <NavLink isHeader={isHeader} navigateTo="about" label="ABOUT" />
    <NavLink isHeader={isHeader} navigateTo="services" label="SERVICES" />
    <NavLink isHeader={isHeader} navigateTo="contact" label="CONTACT US" />
  </div>
)

export default NavLinks
