import React from 'react'
import { BulletPointProps } from './BulletPoint'

type BulletPointListProps = {
  children:
    | React.ReactElement<BulletPointProps>
    | React.ReactElement<BulletPointProps>[]
}

const BulletPointList = ({ children }: BulletPointListProps): JSX.Element => (
  <div className="flex flex-col gap-10 xs:ml-[45%] xs:min-w-fit">
    {children}
  </div>
)

export default BulletPointList
