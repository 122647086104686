import React from 'react'
import FounderText from './FounderText'
import Sam from '../../assets/images/jpg/headshots/Sam.jpg'

/**
 *
 * Frame including the FounderText and image for the Landing Page
 *
 * @return {*}  {JSX.Element}
 */
const FounderFrame = (): JSX.Element => (
  <div className="grid place-items-center">
    <div className="min-w-[1440px] w-full h-[555px] xs:h-[1400px]">
      <div className="relative sm:scale-110 xs:scale-125 sm:ml-10 z-20">
        <img
          src={Sam}
          alt={'Sam Roundy Founder TestPoint Academy'}
          className={
            'absolute w-[380px] h-[500px] -top-[100px] left-[53%] xs:top-[600px] xs:left-[37.5%] shadow-shadow-blue shadow-md z-20'
          }
        />
      </div>
      <div className="flex justify-between w-full bg-dark-blue text-white xs:h-[1600px]">
        <div className="flex-auto ml-[20%] xs:ml-[40%] mt-[80px] bottom-0 xs:mb-20">
          <FounderText />
        </div>
        <div className="xs:h-auto founder-diagonal xs:mb-[100px] xs:-ml-[150px] xs:border-b-[900px] xs:border-l-[900px]" />
      </div>
    </div>
  </div>
)

export default FounderFrame
