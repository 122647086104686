import React from 'react'

import flagSVG from '../../assets/images/svg/angles/ServicesContainerAngle.svg'

type FlagProps = {
  title: string
  list: string[]
}

const Flag = ({ title, list }: FlagProps): JSX.Element => (
  <div className="relative">
    <img src={flagSVG} alt="Blue trapezoid" />
    <div className="absolute top-4 left-8 text-white">
      <div className="text-3xl font-bold">{title}</div>
      <ul className="list-disc list-outside pt-16 pl-10">
        {list.map((item) => (
          <li key={item} className="pb-2">
            {item}
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default Flag
