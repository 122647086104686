import React from 'react'

/**
 *
 * Sam Roundy, first of his name, founder of Testpoint, protector of the Realm.
 * A man who the force is strong with.
 *
 * @return {*}  {JSX.Element}
 */
const FounderText = (): JSX.Element => (
  <>
    <div className="text-purple font-semibold italic text-[100px]">“</div>
    <div className="w-[455px] h-min-fit text-sm mb-[66px] xs:w-[300px] xs:my-24 sm:scale-110 xs:scale-150">
      TestPoint Academy is the only educational institution dedicated to
      finding, developing, and mentoring stellar test engineers. Our programs
      are centered on applying test engineering principles to solving real-world
      problems. Our expert engineers have decades of experience in all aspects
      of test and measurement, including LabVIEW, TestStand, RF testing, fixture
      development, specialized coding, instrumentation, data acquisition and
      analysis, peer reviews, and debugging. After six months of mentoring, a
      typical TestPoint Academy student performs as well as or better than
      someone with over a year of experience. Let us find and build your test
      engineering team!
    </div>
    <div className="text-[28px] font-bold mb-[20px] xs:pt-5">Sam Roundy</div>
    <div className="text-[28px] font-normal xs:pb-5">
      Founder TestPoint Academy
    </div>
  </>
)

export default FounderText
