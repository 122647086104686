import React from 'react'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'

/**
 *
 * Email contact portion on right side of contact page
 *
 * @return {*}  {JSX.Element}
 */
const WriteUs = (): JSX.Element => {
  useHubspotForm({
    portalId: '22410132',
    formId: '6518f8c3-a86c-4ed5-95a5-b797cdef6f3c',
    target: '#hubspot-form',
  })

  return (
    <div className="w-[500px] flex flex-col">
      <div className="font-sans font-bold text-5xl text-dark-blue mb-6">
        Write us
      </div>
      <div id="hubspot-form" className="w-full" />
    </div>
  )
}

export default WriteUs
